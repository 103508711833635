import React, { useState, useEffect } from 'react';
import { CheckIcon as CheckIconSolid } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import { siteMap } from '../utils/sitemap';

import Layout from '../components/layout';

const tiers = [
  {
    name: 'Start',
    href: '#',
    priceMonthly: '€129',
    priceYearly: '€99',
    startingAt: true,
    description: 'For small companies',
    features: [
      'AP Platform: 200 invoices/mo',
      'AP Platform: OCR',
      'AR Platform: 200 invoices/mo',
      'Collection & dunning',
      '2 bank connections',
      'Basic roles',
      '5 users',
    ],
  },
  {
    name: 'Growing',
    href: '#',
    priceMonthly: '€369',
    priceYearly: '€299',
    startingAt: true,
    description: 'For mid-size companies',
    features: [
      'AP Platform: 400 invoices/mo',
      'AP Platform: Ensemble OCR',
      'AR Platform: 400 invoices/mo',
      'Collection & dunning',
      'Customer portal',
      'Cashflow Platform',
      '5 bank connections',
      'Payment integration',
      'Basic & custom roles',
      'Unlimited users',
      'API access',
    ],
  },
  {
    name: 'Enterprise',
    href: '#',
    priceMonthly: 'Custom',
    priceYearly: 'Custom',
    startingAt: false,
    description: 'For companies handling +2000 invoices/mo',
    features: [
      'Volume discounts on AP and AR',
      'Multiple bank connections',
      'API access',
    ],
  },
];

const faqs = [
  {
    id: 1,
    question: 'Which countries do you support?',
    answer:
      "We support EU, UK, US, CA and AU. If your country is not in this list we are extremely sorry, please reach out to use hello@tresorbase.com, and we'll sort it out.",
  },
  {
    id: 2,
    question: 'Do I pay bank fees on payments?',
    answer:
      'Yes, your bank will charge you separately for the payments routed through them.',
  },
  {
    id: 3,
    question: 'What is the cancellation policy?',
    answer: 'We have one month notice period.',
  },
];

function PricingPageOld({ location }) {
  const [yearlyActive, setYearlyActive] = useState(true);
  const activeButton =
    'relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8';
  const InactiveButton =
    'relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2  focus:z-10 sm:w-auto sm:px-8';

  return (
    <Layout>
      <SEO
        title="Pricing"
        description="Try Tresorbase for free. 30-day free trial!"
        path={location.pathname}
      />

      {/* Pricing with four tiers and toggle */}
      <div>
        <div className="pb-6">
          <div className="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center text-center">
              <p className="mt-2 text-3xl font-extrabold sm:text-4xl lg:text-5xl text-gray-200">
                Pricing that scales with you.
              </p>
              {/* <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit
                numquam eligendi quos odit doloribus molestiae voluptatum.
              </p> */}
              <div className="relative mt-6 bg-gray-400 rounded-lg p-0.5 flex self-center sm:mt-8">
                <button
                  type="button"
                  className={yearlyActive ? activeButton : InactiveButton}
                  onClick={() => setYearlyActive(true)}
                >
                  Yearly billing
                </button>
                <button
                  type="button"
                  className={yearlyActive ? InactiveButton : activeButton}
                  onClick={() => setYearlyActive(false)}
                >
                  Monthly billing
                </button>
              </div>
            </div>
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200"
                >
                  <div className="p-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm text-gray-500">
                      {tier.description}
                    </p>
                    <p className="mt-8">
                      {tier.startingAt && (
                        <span className="text-xl font-medium text-gray-900">
                          Starting at{' '}
                        </span>
                      )}
                      <span className="text-4xl font-extrabold text-gray-900">
                        {yearlyActive ? tier.priceYearly : tier.priceMonthly}
                      </span>{' '}
                      <span className="text-base font-medium text-gray-500">
                        /mo
                      </span>
                    </p>
                    <Link
                      to={siteMap.DEMO.link}
                      className="mt-8 block w-full bg-purple-tb-400 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-purple-700"
                    >
                      Buy {tier.name}
                    </Link>
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                      What's included
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <CheckIconSolid
                            className="flex-shrink-0 h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="text-sm text-gray-500">
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* FAQ offset */}
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="space-y-4">
              <h2 className="text-3xl font-extrabold text-gray-200">
                Frequently asked questions
              </h2>
              <p className="text-lg text-gray-300">
                Can’t find the answer you’re looking for? Reach out to our{' '}
                <a
                  href="mailto:hello@tresorbase.com"
                  className="font-medium text-purple-tb-400 hover:text-purple-500"
                >
                  support team
                </a>
                .
              </p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="text-lg leading-6 font-medium text-gray-200">
                      {faq.question}
                    </dt>
                    <dd className="mt-2 text-base text-gray-300">
                      {faq.answer}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function PricingPage({ location }) {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);
  return (
    <Layout>
      <SEO
        title="Pricing"
        description="Try Tresorbase for free. 30-day free trial!"
        path={location.pathname}
      />

      {/* Pricing with four tiers and toggle */}
      <div>
        <div className="pb-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
              <div className="p-6">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  <span className="block">Book a free discovery call</span>
                </h2>
                <dt className="mt-10">
                  <p className="text-lg font-medium leading-6 text-gray-300">
                    AR Automation for mid-size companies
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-200">
                  No more spreadsheets or sticky notes about overdue invoices.
                  Get real-time report and visibility on your invoices.
                </dd>
                <dt className="mt-10">
                  <p className="text-lg font-medium leading-6 text-gray-300">
                    Reduce bad debt
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-200">
                  Stay on top of overdue payments and leverage our AI to
                  proactively credit-check your customers.
                </dd>
                <dt className="mt-10">
                  <p className="text-lg font-medium leading-6 text-gray-300">
                    Improve your customer experience
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-200">
                  Business customers are more demanding, with Tresorbase you get
                  self-service customer portal and a unified payment experience.
                </dd>
              </div>
              <div className="bg-white shadow-sm divide-y divide-gray-200">
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/tresorbase/30min?hide_event_type_details=1"
                  style={{ minWidth: 320, height: 630 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PricingPage;
